import { Link } from '@remix-run/react'

export function Logo({
	className,
	variant,
	to,
}: {
	className?: string
	variant?: 'v2' | 'v3'
	to?: string
}) {
	return (
		<Link to={to ?? '/'} className="group grid leading-snug">
			<span className="sr-only">Proven Valor logo</span>
			<LogoImage variant={variant} className={className} />
		</Link>
	)
}

export function LogoImage({
	variant,
	className,
}: {
	className?: string
	variant?: 'v2' | 'v3'
}) {
	return (
		<img
			src={variant === 'v3' ? '/img/logo-v3.png' : '/img/logo-pv.png'}
			alt="Proven Valor"
			className={className ?? 'h-28 w-auto'}
		/>
	)
}
